<template>
  <b-card>
    <vue-dropzone
      :useCustomSlot="true"
      ref="myVueDropzone"
      id="dropzone"
      :options="dropzoneOptions"
      @vdropzone-removed-file="removeFile"
    >
      <div class="dropzone-custom-content">
        <h3>Drag and drop to upload content!</h3>
        <div>...or click to select a file from your computer</div>
      </div>
    </vue-dropzone>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    BCard,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${process.env.VUE_APP_SERVER_URL}/gallery/upload`,
        paramName: "pictures",
        method: "POST",
        thumbnailWidth: 150,
        addRemoveLinks: true,
        dictRemoveFile: "Remove",
        dictRemoveFileConfirmation:
          "This will delete the file from the server. Do you wish to continue?",
        addRemoveLinks: true,
        duplicateCheck: true,
        acceptedFiles: ".jpeg,.jpg,.png,.gif,.svg,.pdf,.mp4",
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
      },
    };
  },
  methods: {
    removeFile(file) {
      console.log("Removing file");
    },
  },
};
</script>

<style>
</style>